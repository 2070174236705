<template>
    <el-dialog :title="title" :visible.sync="dialogVisible" :width="width" @open="onOpen" @opened="onOpened"
               @close="onClose" @closed="onClosed" :close-on-click-modal="false">
        <slot/>
        <div slot="footer">
            <el-button @click="onCancel">取 消</el-button>
            <el-button v-if="confirm" type="primary" @click="onConfirm">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "TbDialog",
    props: {
        title: {
            type: String,
            default: '信息'
        },
        width: {
            type: String,
            default: '640px'
        },
        confirm: {
            type: Boolean,
            default: true
        }
    },
    data: function () {
        return {
            dialogVisible: false
        }
    },
    methods: {
        show: function () {
            this.dialogVisible = true
        },
        hide: function () {
            this.dialogVisible = false
        },
        toggle: function () {
            this.dialogVisible = !this.dialogVisible
        },
        onOpen: function () {
            this.$emit('onOpen')
        },
        onOpened: function () {
            this.$emit('onOpened')
        },
        onClose: function () {
            this.$emit('onClose')
        },
        onClosed: function () {
            this.$emit('onClosed')
        },
        onCancel: function () {
            this.dialogVisible = false
            this.$emit('onCancel')
        },
        onConfirm: function () {
            this.$emit('onConfirm')
        }
    }
}
</script>

<style scoped>

</style>
