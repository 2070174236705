import { render, staticRenderFns } from "./TbSelect.vue?vue&type=template&id=5576c507&scoped=true"
import script from "./TbSelect.vue?vue&type=script&lang=js"
export * from "./TbSelect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5576c507",
  null
  
)

export default component.exports