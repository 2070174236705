<template>
    <div class="app box">
        <div class="login-box">
            <div class="left-box">
                <img class="logo" src="../assets/logo.png" alt="">
            </div>
            <div class="right-box no-select">
                <el-form ref="dataForm" :model="dataForm" :rules="dataFormRules" label-position="left"
                         style="width: 320px" size="large" @keyup.enter.native="doLogin">
                    <el-form-item>
                        <h2 class="text-center">登录到 创峰达人联盟 管理系统</h2>
                    </el-form-item>
                    <el-form-item prop="account">
                        <el-input v-model="dataForm.account" @keyup.enter.native="doLogin" prefix-icon="el-icon-user"
                                  placeholder="登录账号" clearable></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input v-model="dataForm.password" @keyup.enter.native="doLogin" prefix-icon="el-icon-lock"
                                  type="password" show-password placeholder="登录密码"
                                  clearable></el-input>
                    </el-form-item>
                    <el-form-item prop="captcha">
                        <el-input v-model="dataForm.captcha" @keyup.enter.native="doLogin" prefix-icon="el-icon-mobile"
                                  placeholder="验证码"
                                  clearable>
                            <template slot="append">
                                <img class="captcha" @click="refreshCaptcha" :src="captcha" alt="加载失败">
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button :loading="doing" @click="doLogin" plain type="primary" class="display-block">立 即 登
                            录
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import {getApiUrl} from "@/plugins/api";

export default {
    name: "Index",
    data: function () {
        return {
            doing: false,
            dataForm: {
                account: null,
                password: null,
                captcha: null
            },
            dataFormRules: {
                account: [
                    {required: true, message: '请输入登录账号~', trigger: 'blur'},
                ],
                password: [
                    {required: true, message: '请输入登录密码~', trigger: 'blur'},
                ],
                captcha: [
                    {required: true, message: '请输入验证码~', trigger: 'blur'},
                ]
            },
            captcha: getApiUrl("captcha"),
        }
    },
    created: function () {
        if (this.$helper.getSession("__TOKEN__", false)) {
            this.$router.replace("/main", () => {
            });
        }
    },
    methods: {
        refreshCaptcha: function () {
            this.captcha = getApiUrl("captcha") + '?' + Math.random();
            this.dataForm.captcha = null;
        },
        doLogin: function () {
            this.$refs.dataForm.validate(valid => {
                if (!valid) {
                    return;
                }
                this.$helper._post(this, 'login', this.dataForm, resp => {
                    this.$helper.setSession("__TOKEN__", resp.data.token, false);
                    this.$router.replace("/main", () => {
                    });
                });
            });
        }
    }
}
</script>

<style scoped lang="scss">
.box {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to right, #accbee, #e7f0fd);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .login-box {
        width: 1000px;
        height: 500px;
        border: 5px;
        box-shadow: -4px 5px 10px rgba(0, 0, 0, .4);
        display: flex;

        .left-box, .right-box {
            flex: 1;
        }

        .left-box {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            background: rgba(255,255,255,.2);
            display: flex;
            align-items: center;
            justify-content: center;

            .logo {
                max-width: 400px;
            }
        }

        .right-box {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            background: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    .captcha {
        width: 120px;
        cursor: pointer;
    }
}
</style>
