<template>
    <div class="t-b-list">
        <div class="t-b-list-header">
            <el-form :inline="true" class="t-b-list-header__left">
                <slot name="header"/>
                <el-form-item v-if="options.addBtn && $perm(options.addPerm)">
                    <el-button @click="dialogFormVisible = true" type="primary" icon="el-icon-plus">添 加</el-button>
                </el-form-item>
                <el-form-item v-if="options.delBtn && $perm(options.delPerm)">
                    <el-button @click="deleteSelectedRows" type="danger" icon="el-icon-delete">删 除</el-button>
                </el-form-item>
            </el-form>
            <div>
                <el-button @click="getLists" icon="el-icon-refresh">刷 新</el-button>
                <!--                <el-button icon="el-icon-s-operation">筛 选</el-button>-->
            </div>
        </div>
        <div v-if="tableHeight">
            <el-table
                :data="lists" stripe style="width: 100%" row-key="id" border
                @selection-change="handleSelectionChange" size="mini" v-loading="loading"
                element-loading-text="疯狂加载中..." :max-height="tableHeight">
                <el-table-column
                    fixed
                    type="selection"
                    width="55"/>
                <el-table-column fixed type="index" label="#"/>
                <template v-for="(column, index) of options.columns">
                    <!-- 插入html类型 -->
                    <el-table-column
                        v-if="!column.hide && column.html"
                        :key="index"
                        :prop="column.prop"
                        :label="column.label"
                        :show-overflow-tooltip="true"
                        :min-width="column.width ? column.width : 180">
                        <template slot-scope="scope">
                            <div v-html="column.html({row: scope.row})"></div>
                        </template>
                    </el-table-column>
                    <!-- 常规类型 -->
                    <el-table-column
                        v-else-if="!column.hide"
                        :key="index"
                        :prop="column.prop"
                        :label="column.label"
                        :show-overflow-tooltip="true"
                        :min-width="column.width ? column.width : 120"
                        :formatter="column.formatter ? column.formatter : null"/>
                </template>
                <el-table-column
                    prop="action"
                    fixed="right"
                    :width="options.actionWidth ? options.actionWidth : 140"
                    label="操作">
                    <template slot-scope="scope">
                        <slot name="actions" v-bind="scope"/>
                        <el-button v-if="options.editBtn && $perm(options.editPerm)" @click="doEdit(scope.row)"
                                   type="text" icon="el-icon-edit">
                            编辑
                        </el-button>
                        <el-button v-if="options.delBtn  && $perm(options.delPerm)" @click="doDelete([scope.row.id])"
                                   type="text"
                                   class="color-danger" icon="el-icon-close">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="t-b-list-pager" v-if="options.pager !== false">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="params.page"
                :page-sizes="[10, 15, 30, 50, 100, 200]"
                :page-size="params.per_page"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>
        <el-dialog :title="dataForm.id ? '编辑信息' : '添加信息'" :visible.sync="dialogFormVisible"
                   :width="options.dialogFormWidth ? options.dialogFormWidth : '640px'" @opened="onFormOpened"
                   @close="onFormClose" :close-on-click-modal="false">
            <el-form ref="dataForm" :model="dataForm" :rules="dataFormRules"
                     :label-width="options.formLabelWidth ? options.formLabelWidth : '100px'">
                <template v-for="(column,index) of options.columns">
                    <el-form-item v-if="!Object.prototype.hasOwnProperty.call(column, 'field') || column.field"
                                  :key="index"
                                  :label="column.label"
                                  :prop="column.prop">
                        <el-radio-group v-if="column.type === 'radio'"
                                        v-model="dataForm[column.prop]">
                            <el-radio v-for="item of column.dicData" :key="item.value" :label="item.value">
                                {{ item.label }}
                            </el-radio>
                        </el-radio-group>
                        <div v-else-if="column.type === 'editor'" :id="column.prop"></div>
                        <el-cascader v-else-if="column.type === 'cascader'"
                                     class="display-block"
                                     v-model="dataForm[column.prop]"
                                     :options="column.dicData"
                                     :placeholder="'请选择' + column.label"
                                     :props="Object.assign({ checkStrictly: true, emitPath: false }, column.dicProps ? column.dicProps : {})"
                                     @change="val => column[column.prop] = val" clearable/>
                        <tb-image v-else-if="column.type === 'image'" action="resource.upload.image"
                                  :images="dataForm[column.prop] ? [dataForm[column.prop]] : []" :count="1"
                                  @remove="dataForm[column.prop] = null"
                                  @uploaded=" data => dataForm[column.prop] = data.src"/>
                        <el-select v-else-if="column.type === 'select'" v-model="dataForm[column.prop]"
                                   :placeholder="'请选择' + column.label" class="display-block"
                                   clearable>
                            <el-option
                                v-for="item in column.dicData"
                                :key="item[column.dicProps.value ? column.dicProps.value : 'id']"
                                :label="item[column.dicProps.label ? column.dicProps.label : 'label']"
                                :value="item[column.dicProps.value ? column.dicProps.value : 'id']">
                            </el-option>
                        </el-select>
                        <el-input v-else :type="column.type ? column.type : 'text'"
                                  v-model="dataForm[column.prop]"
                                  :placeholder="'请输入' + column.label" clearable autocomplete="off"
                                  :show-password="column.type === 'password'"></el-input>
                        <div v-if="column.tips" class="color-warning"><i class="el-icon-question"></i> {{ column.tips }}
                        </div>
                    </el-form-item>
                </template>
            </el-form>
            <div slot="footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" :loading="doing" @click="doSubmit">确 定</el-button>
            </div>
        </el-dialog>
        <slot/>
    </div>
</template>

<script>
import E from 'wangeditor'

export default {
    name: "CRUD",
    props: {
        options: Object
    },
    data: function () {
        return {
            tableHeight: null,
            loading: false,
            lists: [],
            params: {
                page: 1,
                per_page: 10
            },
            total: 0,
            selectedRows: [],
            doing: false,
            dialogFormVisible: false,
            dataForm: {},
            dataFormRules: {},
            init: false,
            editors: {}
        }
    },
    watch: {
        options: {
            handler: function () {
                // 构造form和rules
                for (const column of this.options.columns) {
                    // 存在且为false则跳过表单字段则添加
                    if (Object.prototype.hasOwnProperty.call(column, 'formField') && !column.formField) {
                        continue
                    }
                    this.$set(this.dataForm, column.prop, column.value)
                    let rules = [];
                    if (column.required) {
                        rules.push({required: true, message: '请输入' + column.label, trigger: 'blur'})
                    }
                    if (column.rules) {
                        rules = rules.concat(column.rules)
                    }
                    rules.length > 0 && this.$set(this.dataFormRules, column.prop, rules)
                }
            },
            immediate: true
        }
    },
    activated: function () {
        this.getLists()
        this.calcHeight()
    },
    methods: {
        calcHeight: function () {
            let height = document.getElementsByClassName('container')[0].clientHeight - 30;
            let temp = document.getElementsByClassName('t-b-list-header')
            if (temp && temp.length > 0) {
                height -= temp[0].clientHeight
            }
            temp = document.getElementsByClassName('el-pagination')
            if (temp && temp.length > 0) {
                height -= (document.getElementsByClassName('el-pagination')[0].clientHeight + 18)
            }
            this.tableHeight = height
        },
        /**
         * 选中取消操作
         * @param rows
         */
        handleSelectionChange: function (rows) {
            this.selectedRows = rows
        },
        /**
         * 分页量变化
         * @param val
         */
        handleSizeChange: function (val) {
            this.params.page = 1
            this.params.per_page = val
            this.getLists()
        },
        /**
         * 分页变化
         * @param val
         */
        handleCurrentChange: function (val) {
            this.params.page = val
            this.getLists()
        },
        /**
         * 编辑按钮
         * @param row
         */
        doEdit: function (row) {
            this.dataForm = Object.assign({}, row)
            this.dialogFormVisible = true
        },
        /**
         * 批量删除
         */
        deleteSelectedRows: function () {
            if (this.selectedRows.length <= 0) return this.$message.warning("未选中任何数据~")
            this.doDelete(this.selectedRows.map(item => item.id))
        },
        /**
         * 删除操作
         * @param ids
         */
        doDelete: function (ids) {
            this.$confirm('是否确认删除数据？', '提示').then(() => {
                this.$helper._post(this, this.options.delUrl, {ids: ids}, resp => {
                    this.$message.success(resp.message)
                    this.getLists()
                }, false)
            }).catch(() => {
            })
        },
        /**
         * 表单弹窗打开完成回调
         */
        onFormOpened: function () {
            // 初始化富文本编辑器和加载字典类数据
            for (const column of this.options.columns) {
                if (column.type === 'editor') {
                    if (!this.init) { // 未初始化则初始化
                        this.init = true
                        this.editors[column.prop] = new E("#" + (column.prop))
                        // 配置 onchange 回调函数
                        this.editors[column.prop].config.onchange = text => {
                            this.dataForm[column.prop] = text
                        }
                        this.editors[column.prop].config.customUploadImg = (resultFiles, insertImgFn) => {
                            const formData = new FormData()
                            for (const file of resultFiles) {
                                formData.append("files[]", file)
                            }
                            this.$helper._upload(this, 'resource.upload.images', formData, resp => {
                                for (const file of resp.data) {
                                    insertImgFn(file.src)
                                }
                            })
                        }
                        this.editors[column.prop].config.onchangeTimeout = 500
                        this.editors[column.prop].create()
                        this.dataForm[column.prop] && this.editors[column.prop].txt.html(this.dataForm[column.prop])
                    } else { // 已初始化则填充内容
                        this.dataForm[column.prop] && this.editors[column.prop].txt.html(this.dataForm[column.prop])
                    }
                } else if (column.dicUrl) {
                    this.$helper._get(this, column.dicUrl, column.params ? column.params : {}, resp => {
                        column.dicData = resp.data
                    }, false)
                }
            }
        },
        /**
         * 表单关闭前回调
         */
        onFormClose: function () {
            this.$refs.dataForm.resetFields()
            this.selectedRows = []
            this.dataForm = {}
            // 重新设置  否则编辑时重置会保留数据
            for (const column of this.options.columns) {
                // 存在且为false则跳过表单字段则添加
                if (Object.prototype.hasOwnProperty.call(column, 'formField') && !column.formField) {
                    continue
                }
                this.$set(this.dataForm, column.prop, column.value)
            }
        },
        /**
         * 表单提交操作
         */
        doSubmit: function () {
            this.$refs.dataForm.validate(valid => {
                if (!valid) {
                    return
                }
                this.$helper._post(this, this.dataForm.id ? this.options.editUrl : this.options.addUrl, this.dataForm, resp => {
                    this.$message.success(resp.message)
                    this.dialogFormVisible = false
                    this.getLists()
                })
            })
        },
        /**
         * 列表加载操作
         */
        getLists: function () {
            if (!this.options.listUrl) {
                return
            }
            this.$helper._get(this, this.options.listUrl, Object.assign(this.options.params ? this.options.params : {}, this.params), resp => {
                if (this.options.pager) {
                    this.lists = resp.data.data
                    this.total = resp.data.total
                } else {
                    this.lists = resp.data
                }
            });
        },
        /**
         * 获取列表选中行
         * @returns {[]}
         */
        getSelectedRows: function () {
            return this.selectedRows
        }
    }
}
</script>

<style scoped lang="scss">
.t-b-list {
    display: flex;
    flex-direction: column;

    .t-b-list-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .t-b-list-header__left {
            flex: 1;
        }
    }

    .t-b-list-pager {
        text-align: right;
        margin-top: 18px;
    }

    .uploader-icon {
        width: 100px;
        height: 100px;
        border: 1px dashed #8c939d;
        border-radius: 5px;
        color: #8c939d;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.user-avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
}
</style>
