import Vue from "vue"

import {getApiUrl} from "@/plugins/api";

Vue.prototype.$helper = {
    /**
     * http get 请求
     * @param _this
     * @param uri
     * @param params
     * @param callback
     * @param loading
     * @param catchCallback
     * @param finallyCallback
     * @private
     */
    _get: function (_this, uri, params = {}, callback = undefined, loading = true, catchCallback = undefined, finallyCallback = undefined) {
        if (loading && Object.prototype.hasOwnProperty.call(_this, 'loading')) {
            _this.loading = true;
        }
        _this.http.get(getApiUrl(uri), {
            params: params
        }).then(resp => {
            // this.onResponse(_this, resp, callback);
            if (resp.data.code === 200) {
                callback && callback(resp.data)
            } else if (resp.data.code === 403) {
                this._get(_this, uri, params, callback, catchCallback, finallyCallback)
            } else if (resp.data.code === 401) {
                _this.$message.warning(resp.data.message);
                this.removeSession();
                setTimeout(() => {
                    _this.$router.replace('/', () => {
                    });
                }, 2000);
            } else {
                _this.$message.error(resp.data.message);
            }
        }).catch(err => {
            _this.$message.error(`网络请求错误：${err.toString()}`);
            catchCallback && catchCallback(err);
        }).finally(() => {
            _this.loading = false;
            finallyCallback && finallyCallback();
        });
    },
    /**
     * http post请求
     * @param _this
     * @param uri
     * @param params
     * @param callback
     * @param doing
     * @param catchCallback
     * @param finallyCallback
     * @private
     */
    _post: function (_this, uri, params = {}, callback = undefined, doing = true, catchCallback = undefined, finallyCallback = undefined) {
        if (doing && Object.prototype.hasOwnProperty.call(_this, 'doing')) {
            _this.doing = true;
        }
        _this.http.post(getApiUrl(uri), params)
            .then(resp => {
                if (resp.data.code === 200) {
                    callback && callback(resp.data)
                } else if (resp.data.code === 403) {
                    this._post(_this, uri, params, callback, catchCallback, finallyCallback)
                } else if (resp.data.code === 401) {
                    _this.$message.warning(resp.data.message);
                    this.removeSession();
                    setTimeout(() => {
                        _this.$router.replace('/', () => {
                        });
                    }, 2000);
                } else {
                    _this.$message.error(resp.data.message);
                }
            }).catch(err => {
            _this.$message.error(`网络请求错误：${err.toString()}`);
            catchCallback && catchCallback(err);
        }).finally(() => {
            _this.doing = false;
            finallyCallback && finallyCallback();
        });
    },
    _upload: function (_this, uri, params = {}, callback = undefined, catchCallback = undefined, finallyCallback = undefined) {
        _this.http.post(getApiUrl(uri), params, {
            'Content-Type': 'multipart/form-data'
        }).then(resp => {
            if (resp.data.code === 200) {
                callback && callback(resp.data)
            } else if (resp.data.code === 403) {
                this._upload(_this, uri, params, callback, catchCallback, finallyCallback)
            } else if (resp.data.code === 401) {
                _this.$message.warning(resp.data.message);
                this.removeSession();
                setTimeout(() => {
                    _this.$router.replace('/', () => {
                    });
                }, 2000);
            } else {
                _this.$message.error(resp.data.message);
            }
        }).catch(err => {
            _this.$message.error(`网络请求错误：${err.toString()}`);
            catchCallback && catchCallback(err);
        }).finally(() => {
            _this.doing = false;
            finallyCallback && finallyCallback();
        });
    },
    /**
     * 获取存储数据
     * @param key               指定获取key
     * @param isObject          是否为对象数据
     * @returns {object|string}    对象或字符串
     */
    getStorage: function (key = undefined, isObject = true) {
        const valueString = localStorage.getItem(key);
        return valueString && isObject ? JSON.parse(valueString) : valueString;
    },
    /**
     * 存储数据,key为空或数据为空则不存储
     * @param key       指定删除key
     * @param data      存储数据
     * @param isObject  是否为对象数据
     */
    setStorage: function (key = undefined, data = undefined, isObject = true) {
        if (!key || !data) {
            return;
        }
        localStorage.setItem(key, isObject ? JSON.stringify(data) : data);
    },
    removeStorage: function (key = undefined) {
        if (key) {
            localStorage.removeItem(key);
        } else {
            localStorage.clear();
        }
    },
    /**
     * 获取指定key的session值
     * @param key               指定key
     * @param isObject          是否为对象数据
     * @returns {object|string} 对象或字符串
     */
    getSession: function (key = undefined, isObject = true) {
        const valueString = sessionStorage.getItem(key);
        return valueString && isObject ? JSON.parse(valueString) : valueString;
    },
    /**
     * 设置session, 未指定key或值则不设置
     * @param key       存储key
     * @param data      存储值
     * @param isObject  是否为对象数据
     */
    setSession: function (key = undefined, data = undefined, isObject = true) {
        if (!key || !data) {
            return
        }
        sessionStorage.setItem(key, isObject ? JSON.stringify(data) : data);
    },
    /**
     * 删除session,未指定则清空
     * @param key 指定清空key
     */
    removeSession: function (key = undefined) {
        key ? sessionStorage.removeItem(key) : sessionStorage.clear();
    },
}
