<template>
    <div class="tb-image">
        <template v-for="(item,index) in images">
            <div v-if="item" :key="index" class="tb-image__item">
                <el-image
                    class="tb-image__item-image"
                    :src="item"
                    fit="cover"/>
                <div class="tb-image__item-actions">
                    <i class="action el-icon-zoom-in" @click="preview(index)"></i>
                    <i class="action el-icon-delete" @click="remove(index)"></i>
                </div>
            </div>
        </template>
        <el-upload
            v-if="!images || images.length < count"
            class="avatar-uploader"
            :action="action"
            :http-request="doUpload"
            :show-file-list="false">
            <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-dialog
            title="提示"
            :visible.sync="previewVisible">
            <span slot="title"></span>
            <img style="width: 100%" :src="images[previewIndex]" alt="">
        </el-dialog>
    </div>
</template>

<script>

export default {
    name: "TbImage",
    props: {
        count: {
            type: Number,
            default: 999
        },
        images: Array,
        action: String
    },
    data: function () {
        return {
            previewVisible: false,
            previewIndex: -1
        }
    },
    methods: {
        preview: function (index) {
            this.previewIndex = index
            this.previewVisible = true
        },
        remove: function (index) {
            this.$emit('remove', index)
        },
        doUpload: function (params) {
            const formData = new FormData()
            formData.append("file", params.file)
            this.$helper._upload(this, this.action, formData, resp => {
                this.$emit('uploaded', resp.data)
            })
        }
    }
}
</script>

<style scoped lang="scss">
.tb-image {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .tb-image__item {
        position: relative;
        margin-right: 10px;
        display: flex;

        &:hover .tb-image__item-actions {
            display: flex;
        }


        .tb-image__item-image, .tb-image__item-actions {
            width: 100px;
            height: 100px;
            border-radius: 5px;
        }

        .tb-image__item-actions {
            position: absolute;
            top: 0;
            left: 0;
            display: none;
            background: rgba(0, 0, 0, .2);
            align-items: center;
            justify-content: space-around;

            .action {
                font-size: 20px;
                color: white;
                cursor: pointer;
            }
        }
    }

    .avatar-uploader-icon {
        font-size: 24px;
        color: #8c939d;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        border: 1px dashed #d9d9d9;
        border-radius: 5px;
    }
}
</style>
