import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import {RouterTabRoutes} from 'vue-router-tab'

// 异步加载页面组件
const importPage = view => () => import(`../views/${view}.vue`);

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index
    },
    {
        path: '/main',
        name: 'main',
        component: importPage('Main'),
        children: [
            ...RouterTabRoutes,
            {
                path: '/',
                component: importPage('Welcome'),
                closable: false,
                meta: {
                    title: '首页',
                }
            },
            {
                path: '/info',
                component: importPage('admin/admin/Info'),
                closable: true,
                meta: {
                    title: '个人信息',
                }
            },
        ]
    }
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    NProgress.start();
    next()
})

router.afterEach(() => {
    NProgress.done()
})
export default router
