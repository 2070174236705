<template>
    <el-select ref="visitCardInput" :class="[styles]"
               v-model="value" :placeholder="options.placeholder"
               @clear="onClear" @visible-change="onVisibleChange"
               @change="onSelectedChange"
               filterable :filter-method="doFilter"
               clearable default-first-option>
        <el-option
            v-for="item in lists"
            :key="item.id"
            :label="label(item)"
            :value="item.id">
        </el-option>
    </el-select>
</template>

<script>
export default {
    name: "TbSelect",
    props: {
        options: Object,
        styles: {
            type: String,
            default: 'display-block'
        }
    },
    data: function () {
        return {
            sources: [],
            lists: [],
            value: null
        }
    },
    computed: {
        label: function () {
            // eslint-disable-next-line no-unused-vars
            return item => {
                return eval(this.options.label)
            }
        }
    },
    created() {
        this.$EventBus.$on('done', () => {
            this.value = null
        })
    },
    methods: {
        setValue: function (val) {
            this.value = val === 0 ? null : val
        },
        setData: function (data = []) {
            this.sources = data
            this.doFilter(null)
        },
        onClear: function () {
            this.doFilter(null)
        },
        onVisibleChange: function () {
            this.doFilter(null)
        },
        onSelectedChange: function (val) {
            this.$emit('change', val)
        },
        doFilter: function (value = null) {
            this.lists = this.sources.filter(item => this.options.filter(item, value))
        },
        getLists: function () {
            if (!this.options.listUrl) {
                return
            }
            this.$helper._get(this, this.options.listUrl, this.options.params ? this.options.params : {}, resp => {
                this.sources = resp.data
                this.doFilter(null)
            });
        }
    }
}
</script>

<style scoped>

</style>
