"use strict";
import Vue from 'vue'

// 引入crud组件
import CRUD from "@/components/CRUD"

Vue.component('crud', CRUD)

// 引入弹窗组件
import TbDialog from "@/components/TbDialog"

Vue.component('tb-dialog', TbDialog)

// 引入图片组件
import TbImage from "@/components/TbImage"

Vue.component('tb-image', TbImage)

// 引入下拉组件
import TbSelect from "@/components/TbSelect"

Vue.component('tb-select', TbSelect)
