<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>

export default {
    name: 'app',
    created() {
        const menus = this.$helper.getSession('menus')
        if (menus && menus.length > 0) {
            const routers = []
            for (const router of menus) {
                routers.push({
                    name: router.path,
                    path: router.path,
                    component: () => import(`./views${router.path}.vue`),
                    meta: {
                        title: router.name,
                    }
                })
            }
            this.$router.addRoutes([{
                path: '/main',
                name: 'main',
                component: () => import(`./views/Main.vue`),
                children: routers
            }])
        }
    }
}
</script>
