"use strict"
const BASE_URL = '/'
const API_BASE_URL = ''
// const API_BASE_URL = 'api/'
const URIS = {
    'captcha': 'login/index/captcha',
    'login': 'login/index/auth',
    'admin.info': 'admin/admin/info',
    'admin.edit': 'admin/admin/edit',
    'admin.pass': 'admin/admin/update_password',
    'admin.menu.info': 'admin/menu/info',
    'admin.menu.lists': 'admin/menu/lists',
    'admin.menu.add': 'admin/menu/add',
    'admin.menu.edit': 'admin/menu/edit',
    'admin.menu.delete': 'admin/menu/deletes',
    'resource.upload.images': 'resource/upload/images',
    'resource.upload.image': 'resource/upload/image',
    'admin.role.page': 'admin/role/page',
    'admin.role.list': 'admin/role/list',
    'admin.role.detail': 'admin/role/detail',
    'admin.role.add': 'admin/role/add',
    'admin.role.edit': 'admin/role/edit',
    'admin.role.delete': 'admin/role/delete',
    'admin.role.menu.auth': 'admin/roleMenu/auth',
    'admin.role.menu.submit': 'admin/roleMenu/submit',
    'develop.generator.page': 'develop/generator/page',
    'develop.generator.add': 'develop/generator/add',
    'develop.generator.edit': 'develop/generator/edit',
    'develop.generator.delete': 'develop/generator/delete',
    'develop.generator.generate': 'develop/generator/generate',
    'admin.post.page': 'admin/post/page',
    'admin.post.list': 'admin/post/list',
    'admin.post.add': 'admin/post/add',
    'admin.post.edit': 'admin/post/edit',
    'admin.post.delete': 'admin/post/delete',
    'admin.dept.page': 'admin/dept/page',
    'admin.dept.list': 'admin/dept/list',
    'admin.dept.add': 'admin/dept/add',
    'admin.dept.edit': 'admin/dept/edit',
    'admin.dept.delete': 'admin/dept/delete',
    'admin.dept.tree': 'admin/dept/tree',
    'admin.admin.page': 'admin/admin/page',
    'admin.admin.add': 'admin/admin/add',
    'admin.admin.edit': 'admin/admin/edit',
    'admin.admin.reset': 'admin/admin/reset_pass',
    'admin.admin.delete': 'admin/admin/delete',
    'resource.upload.config': 'resource/setting/upload_config',
    'resource.upload.update': 'resource/setting/upload_update',
    'resource.sms.config': 'resource/setting/sms_config',
    'resource.sms.update': 'resource/setting/sms_update',
    'resource.smsTemplate.page': 'resource/SmsTemplate/page',
    'resource.smsTemplate.add': 'resource/SmsTemplate/add',
    'resource.smsTemplate.edit': 'resource/SmsTemplate/edit',
    'resource.smsTemplate.delete': 'resource/SmsTemplate/delete',
    'resource.smsTemplate.codes': 'resource/SmsTemplate/codes',
    'league.Member.page': 'league/Member/page',
    'league.Member.detail': 'league/Member/detail',
    'league.Member.add': 'league/Member/add',
    'league.Member.edit': 'league/Member/edit',
    'league.Member.upgrade': 'league/Member/upgrade',
    'league.Member.delete': 'league/Member/delete',
    'league.JoinOrder.page': 'league/JoinOrder/page',
    'league.JoinOrder.detail': 'league/JoinOrder/detail',
    'league.JoinOrder.add': 'league/JoinOrder/add',
    'league.JoinOrder.edit': 'league/JoinOrder/edit',
    'league.JoinOrder.delete': 'league/JoinOrder/delete',
    'league.Withdrawal.page': 'league/Withdrawal/page',
    'league.Withdrawal.detail': 'league/Withdrawal/detail',
    'league.Withdrawal.add': 'league/Withdrawal/add',
    'league.Withdrawal.edit': 'league/Withdrawal/edit',
    'league.Withdrawal.delete': 'league/Withdrawal/delete',
    'league.Withdrawal.calc': 'league/Withdrawal/calc',
    'league.PublishResult.page': 'league/PublishResult/page',
    'league.PublishResult.detail': 'league/PublishResult/detail',
    'league.PublishResult.add': 'league/PublishResult/add',
    'league.PublishResult.edit': 'league/PublishResult/edit',
    'league.PublishResult.delete': 'league/PublishResult/delete',
    'league.PublishResult.calc': 'league/PublishResult/calc',
    'league.MerchantType.page': 'league/MerchantType/page',
    'league.MerchantType.list': 'league/MerchantType/list',
    'league.MerchantType.detail': 'league/MerchantType/detail',
    'league.MerchantType.add': 'league/MerchantType/add',
    'league.MerchantType.edit': 'league/MerchantType/edit',
    'league.MerchantType.delete': 'league/MerchantType/delete',
    'league.Merchant.page': 'league/Merchant/page',
    'league.Merchant.list': 'league/Merchant/list',
    'league.Merchant.detail': 'league/Merchant/detail',
    'league.Merchant.add': 'league/Merchant/add',
    'league.Merchant.edit': 'league/Merchant/edit',
    'league.Merchant.delete': 'league/Merchant/delete',
    'league.IndexSetting.config': 'league/Setting/getIndexConfig',
    'league.IndexSetting.update': 'league/Setting/updateIndexSetting',
    'league.SystemSetting.config': 'league/Setting/getSystemSetting',
    'league.SystemSetting.update': 'league/Setting/updateSystemSetting',
    'league.DistributionSetting.config': 'league/Setting/getDistributionSetting',
    'league.DistributionSetting.update': 'league/Setting/updateDistributionSetting',
    'league.systemSetting.config': 'league/Setting/getSystemConfig',
    'league.systemSetting.update': 'league/Setting/updateSystemSetting',
    'league.MerchantSetMeal.page': 'league/MerchantSetMeal/page',
    'league.MerchantSetMeal.detail': 'league/MerchantSetMeal/detail',
    'league.MerchantSetMeal.add': 'league/MerchantSetMeal/add',
    'league.MerchantSetMeal.edit': 'league/MerchantSetMeal/edit',
    'league.MerchantSetMeal.delete': 'league/MerchantSetMeal/delete',
    'admin.ArticleCategory.page': 'admin/ArticleCategory/page',
    'admin.ArticleCategory.tree': 'admin/ArticleCategory/tree',
    'admin.ArticleCategory.detail': 'admin/ArticleCategory/detail',
    'admin.ArticleCategory.add': 'admin/ArticleCategory/add',
    'admin.ArticleCategory.edit': 'admin/ArticleCategory/edit',
    'admin.ArticleCategory.delete': 'admin/ArticleCategory/delete',
    'admin.Article.page': 'admin/Article/page',
    'admin.Article.detail': 'admin/Article/detail',
    'admin.Article.add': 'admin/Article/add',
    'admin.Article.edit': 'admin/Article/edit',
    'admin.Article.delete': 'admin/Article/delete',
    'league.MemberLevel.page': 'league/MemberLevel/page',
    'league.MemberLevel.list': 'league/MemberLevel/list',
    'league.MemberLevel.detail': 'league/MemberLevel/detail',
    'league.MemberLevel.add': 'league/MemberLevel/add',
    'league.MemberLevel.edit': 'league/MemberLevel/edit',
    'league.MemberLevel.delete': 'league/MemberLevel/delete',
    'league.MemberProfileBackup.page': 'league/MemberProfileBackup/page',
    'league.MemberProfileBackup.detail': 'league/MemberProfileBackup/detail',
    'league.MemberProfileBackup.add': 'league/MemberProfileBackup/add',
    'league.MemberProfileBackup.edit': 'league/MemberProfileBackup/edit',
    'league.MemberProfileBackup.delete': 'league/MemberProfileBackup/delete',
    'league.MemberProfileBackup.reject': 'league/MemberProfileBackup/reject',
    'league.MemberProfileBackup.success': 'league/MemberProfileBackup/audit',
    'league.Feedback.page': 'league/Feedback/page',
    'league.Feedback.detail': 'league/Feedback/detail',
    'league.Feedback.add': 'league/Feedback/add',
    'league.Feedback.edit': 'league/Feedback/edit',
    'league.Feedback.delete': 'league/Feedback/delete',
    'league.MemberProfile.page': 'league/MemberProfile/page',
    'league.MemberProfile.detail': 'league/MemberProfile/detail',
    'league.MemberProfile.add': 'league/MemberProfile/add',
    'league.MemberProfile.edit': 'league/MemberProfile/edit',
    'league.MemberProfile.delete': 'league/MemberProfile/delete',
    'league.Site.page': 'league/Site/page',
    'league.Site.list': 'league/Site/lists',
    'league.Site.detail': 'league/Site/detail',
    'league.Site.add': 'league/Site/add',
    'league.Site.edit': 'league/Site/edit',
    'league.Site.delete': 'league/Site/delete',
    'league.SiteWithdrawal.page': 'league/SiteWithdrawal/page',
    'league.SiteWithdrawal.detail': 'league/SiteWithdrawal/detail',
    'league.SiteWithdrawal.add': 'league/SiteWithdrawal/add',
    'league.SiteWithdrawal.edit': 'league/SiteWithdrawal/edit',
    'league.SiteWithdrawal.delete': 'league/SiteWithdrawal/delete',
    'league.SiteWithdrawal.info': 'league/SiteWithdrawal/info',
    'league.SiteWithdrawal.apply': 'league/SiteWithdrawal/apply',
    'league.SiteWithdrawal.doCalc': 'league/SiteWithdrawal/doCalc',
    'admin.AdvertPosition.page': 'admin/AdvertPosition/page',
    'admin.AdvertPosition.detail': 'admin/AdvertPosition/detail',
    'admin.AdvertPosition.add': 'admin/AdvertPosition/add',
    'admin.AdvertPosition.edit': 'admin/AdvertPosition/edit',
    'admin.AdvertPosition.delete': 'admin/AdvertPosition/delete',
    'admin.AdvertPosition.lists': 'admin/AdvertPosition/lists',
    'admin.Advert.page': 'admin/Advert/page',
    'admin.Advert.detail': 'admin/Advert/detail',
    'admin.Advert.add': 'admin/Advert/add',
    'admin.Advert.edit': 'admin/Advert/edit',
    'admin.Advert.delete': 'admin/Advert/delete',
    'league.Analysis.welcome': 'league/Analysis/welcome',
}

export function getApiUrl(key = null) {
    return key ? BASE_URL + API_BASE_URL + URIS[key] : ''
}

export function getHost() {
    return BASE_URL
}
