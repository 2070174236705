import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import RouterTab from 'vue-router-tab'
import 'vue-router-tab/dist/lib/vue-router-tab.css'
import "./assets/app.scss"
import "./plugins/helper"
import "./plugins/components"

Vue.config.productionTip = false

Vue.use(RouterTab)

Vue.prototype.$perm = perm => store.state.permissions.includes(perm)

// event bus
Vue.prototype.$EventBus = new Vue()

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
